import { Box, Container, Grid, ThemeProvider, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Menu5 from '../../../styles/MenuStyles/Menu5';
import ContactUsForm from '../../../components/contactUsForm/contactUsForm';
import arrow from '../../../assets/images/arrow.svg';
import lolypop from '../../../assets/images/lolypop.svg';
import { createTheme } from '@mui/material/styles';

import './../../../../../../styles/fonts/newFonts.css';

import Navbar1 from '../../../styles/NavbarStyles/Navbar1';
import Navbar2 from '../../../styles/NavbarStyles/Navbar2';
import Navbar3 from '../../../styles/NavbarStyles/Navbar3';
import Navbar4 from '../../../styles/NavbarStyles/Navbar4';
import Navbar5 from '../../../styles/NavbarStyles/Navbar5';

import Footer1 from '../../../styles/FooterStyles/Footer1';
import Footer2 from '../../../styles/FooterStyles/Footer2';
import Footer3 from '../../../styles/FooterStyles/Footer3';
import Footer4 from '../../../styles/FooterStyles/Footer4';
import Footer5 from '../../../styles/FooterStyles/Footer5';
import Header1 from './../../../styles/HeaderStyles/Header1/Header';
import Header2 from './../../../styles/HeaderStyles/Header2/Header';
import Header3 from './../../../styles/HeaderStyles/Header3/Header';
import { useStateContext } from '../../../api/stateContext';

import TextFieldDark1 from '../../../styles/TextFieldStyles/TextFieldDark/TextFieldDark1';
import TextFieldDark2 from '../../../styles/TextFieldStyles/TextFieldDark/TextFieldDark2';
import TextFieldLabelBox from '../../../styles/TextFieldStyles/TextFieldLabelBox';
import TextFieldLabelRound from '../../../styles/TextFieldStyles/TextFieldLabelRound';
import TextFieldLight1 from '../../../styles/TextFieldStyles/TextFieldLight/TextFieldLight1';
import TextFieldLight2 from '../../../styles/TextFieldStyles/TextFieldLight/TextFieldLight2';
import TextField3 from '../../../styles/TextFieldStyles/TextField3/TextField3';
import TextField2 from './../../../styles/TextFieldStyles/TextField2/TextField2';
import TextField1 from './../../../styles/TextFieldStyles/TextField1/TextField1';

import Layout1 from '../../../styles/cardLayouts/layout1/layout1';
import Layout2 from '../../../styles/cardLayouts/layout2/layout2';
import Layout3 from '../../../styles/cardLayouts/layout3/layout3';
import Layout4 from '../../../styles/cardLayouts/layout4/layout4';
import Layout5 from '../../../styles/cardLayouts/layout5/layout5';
import axios from 'axios';
export default function CustomizationServiceLandingPage() {
  const { state, dispatch } = useStateContext();

  var fontFamily = {
    fontFamily: `"${state.activeFont}",sans-serif !important`,
  };
  const theme = createTheme({
    typography: {
      fontFamily: `"${state.activeFont}",sans-serif !important`,
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: `"${state.activeFont}",sans-serif !important`,
          },
        },
      },
    },
  });

  var selectedNavFooter = state.activeNavFooter;
  const navbarComponents = {
    1: <Navbar1 />,
    2: <Navbar2 />,
    3: <Navbar3 />,
    4: <Navbar4 />,
    5: <Navbar5 />,
  };
  var renderNavbar = () => navbarComponents[selectedNavFooter] || null;

  const footerComponents = {
    1: <Footer1 />,
    2: <Footer2 />,
    3: <Footer3 />,
    4: <Footer4 />,
    5: <Footer5 />,
  };
  var renderFooter = () => footerComponents[selectedNavFooter] || null;

  var selectedHeader = state.activeLandingPage;
  const headerComponents = {
    1: <Header1 />,
    2: <Header2 />,
    3: <Header3 />,
  };
  var renderHeader = () => headerComponents[selectedHeader] || null;

  var selectedCardLayoutComponentMenu = state.activeCardLayoutMenu;
  var selectedCardLayoutComponentOurSpecial = state.activeCardLayoutOurSpecial;

  const cardLayoutComponents = {
    Menu: {
      1: <Layout1 cardStyle={state.activeCardStyleMenu} />,
      2: <Layout2 cardStyle={state.activeCardStyleMenu} />,
      3: <Layout3 cardStyle={state.activeCardStyleMenu} />,
      4: <Layout4 cardStyle={state.activeCardStyleMenu} />,
      5: <Layout5 cardStyle={state.activeCardStyleMenu} />,
    },
    OurSpecial: {
      1: <Layout1 cardStyle={state.activeCardStyleOurSpecial} />,
      2: <Layout2 cardStyle={state.activeCardStyleOurSpecial} />,
      3: <Layout3 cardStyle={state.activeCardStyleOurSpecial} />,
      4: <Layout4 cardStyle={state.activeCardStyleOurSpecial} />,
      5: <Layout5 cardStyle={state.activeCardStyleOurSpecial} />,
    },
  };
  var renderCardLayoutMenu = () =>
    cardLayoutComponents['Menu'][selectedCardLayoutComponentMenu] || null;

  var renderCardLayoutOurSpecial = () =>
    cardLayoutComponents['OurSpecial'][selectedCardLayoutComponentOurSpecial] ||
    null;
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ background: 'white', ...fontFamily, position: 'relative' }}>
          {/* Navbar Render */}
          {renderNavbar()}
          {renderHeader()}
          <Box
            maxWidth="xlz"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '40px',
            }}
          >
            <Box sx={{ width: '40%', mt: 11 }}>
              <TextField3 placeholder={'Search'} color="" />
            </Box>
            {state.activeCardLayoutMenu === 1 ? (
              <Box
                sx={{
                  pt: state.activeCardStyleMenu === 1 ? 9 : 0,
                }}
              >
                {/* Card Layout Render */}
                {renderCardLayoutMenu()}
              </Box>
            ) : (
              <Container
                sx={{
                  pt: state.activeCardStyleMenu === 1 ? 9 : 0,
                }}
              >
                {/* Card Layout Render */}
                {renderCardLayoutMenu()}
              </Container>
            )}

            <Container sx={{ textAlign: 'center', mb: 2 }}>
              <Typography
                variant="h4"
                lineHeight={'56px'}
                sx={{
                  fontSize: '32px',
                  fontWeight: 700,
                  letterSpacing: '2%',
                  color: '#5A4F48',
                  mb: state.activeCardStyleOurSpecial === 1 ? 7 : 5,
                }}
              >
                Our Special
              </Typography>
              {state.activeCardLayoutOurSpecial === 1 ? (
                <Box
                  sx={{
                    pt: state.activeCardStyleOurSpecial === 1 ? 9 : 0,
                  }}
                >
                  {/* Card Layout Render */}
                  {renderCardLayoutOurSpecial()}
                </Box>
              ) : (
                <Container
                  sx={{
                    pt: state.activeCardStyleOurSpecial === 1 ? 9 : 0,
                  }}
                >
                  {/* Card Layout Render */}
                  {renderCardLayoutOurSpecial()}
                </Container>
              )}
            </Container>
            <Box sx={{ textAlign: 'center', mb: 5 }}>
              <Typography
                variant="h4"
                lineHeight={'56px'}
                sx={{
                  fontSize: '32px',
                  fontWeight: 700,
                  letterSpacing: '2%',
                  color: '#5A4F48',
                  mb: 5,
                }}
              >
                Timing
              </Typography>
              <Menu5 />
            </Box>
          </Box>
          <Grid
            container
            sx={{ width: '100%', bgcolor: 'rgba(250, 231, 195, 1)' }}
          >
            <Grid
              item
              xs={2}
              sx={{
                backgroundImage: `url(${arrow})`,
                backgroundSize: '70%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'start',
                opacity: 0.7,
              }}
            ></Grid>
            <Grid item xs={8} py={5}>
              <ContactUsForm />{' '}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                backgroundImage: `url(${lolypop})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
              }}
            ></Grid>
          </Grid>
          <Box>{renderFooter()}</Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
